import { gql } from '@apollo/client';

export const HNF_ORGANIZATION = gql`
  query wodscreenOrganizationQuery($id: String) {
    hansnfranz {
      organization(where: { entity_uuid: { _eq: $id } }) {
        remote_organization {
          id
          name
          imageUrl
        }
      }
    }
  }
`;

export const HNF_WORKOUT = gql`
  query wodscreenWorkoutsQuery($ids: [Int!]) {
    hansnfranz {
      workout(where: { id: { _in: $ids } }) {
        name
        id
        description
        remote_workout {
          modalityList
        }
      }
    }
  }
`;

export const TRACK = gql`
  query wodscreenTracksQuery($organization_id: String!, $event_date: date) {
    hansnfranz {
      organization(where: { entity_uuid: { _eq: $organization_id } }) {
        gym {
          gym_tracks(where: { track: { active: { _eq: "1" } } }) {
            track {
              name
              id
              track_events(
                where: { _and: { event_date: { _eq: $event_date } } }
              ) {
                id
                title
                instructions
                track_event_workout {
                  workout {
                    id
                    workout_movements {
                      exercise_id
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query locationsQuery($organizationId: uuid!, $date: date!) {
    stringer_ro {
      location(where: { organization_id: { _eq: $organizationId } }) {
        name
        id
        class_records(where: { date: { _eq: $date } }) {
          name
          time_of_day
          class_series_id
          id
          class_type_id
        }
      }
    }
  }
`;

export const GET_CLASS_DETAILS = gql`
  query getClassDetailsQuery($id: uuid = "") {
    stringer_ro {
      class_record(where: { id: { _eq: $id } }) {
        capacity
        time_of_day
        name
        id
        class_type_id
        class_series_id
        attendance_records {
          freamon_member {
            first_name
            last_name
            remote_member {
              photoUrl
            }
          }
          status
        }
      }
    }
  }
`;

export const GET_ORGANIZATIONS_QUERY = gql`
  query getMemberOrganizations($member_uuid: uuid!, $member_string: String!) {
    iam_rbac_organization_user_scope_role(
      where: { user_id: { _eq: $member_uuid } }
    ) {
      user_id
      organization_id
      scope
      role
      hansnfranzfdw_organization {
        entity_uuid
        name
        remote_organization {
          imageUrl
        }
      }
    }
    hansnfranz {
      organization(where: { owner: { entity_uuid: { _eq: $member_string } } }) {
        entity_uuid
        owner {
          id
          entity_uuid
          last_name
          first_name
        }
        remote_organization {
          imageUrl
        }
        name
      }
    }
  }
`;
